body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
   --orange: #EF7101;
   --blue: #012D6A;
   --dark-blue: #002552;
   --white: #E8EBF0;
   --muted-white: #A0ADC2;
   --button-white: #FFFFFF;

}

textarea,
input {
   -webkit-touch-callout: text;
   -webkit-user-select: text;
   -khtml-user-select: text;
   -moz-user-select: text;
   -ms-user-select: text;
   user-select: text;
}

html {
   scroll-behavior: smooth;
}