.workflow-header {
   width: 100%;
   display: flex;
   flex: 0 0 75px;
   align-items: center;
   margin-left: 8px;

   font-size: 11px;
   font-weight: 600;
   text-transform: uppercase;

}