@import "~@o4c/css/mixins/elevations";

.no-tasks-container {
   padding: 24px 16px;
   display: flex;
   width: 100%;
   box-sizing: border-box;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;


   .info-text {
      color: var(--white);
      font-size: medium;
      font-weight: bold;
      padding: 16px;
   }
   .icon-wrapper {
      height: 96px;
      width: 96px;
      border-radius: 96px;
      background-color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
   }
   i.icon {
      font-size: 48px;
      color: var(--blue);
   }
}
