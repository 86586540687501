@import '~@o4c/css/components/spinners';

body {
   display: flex;
   flex-direction: column;
   font-family: "Roboto";
   font-size: 12px;
   background-color: var(--dark-grey);
   color: var(--white);
   max-width: 1024px;
   min-height: 756px;
   overflow-y: hidden;
   margin: 0 auto;
}

.container {
   display: flex;
   flex-direction: column;
   padding: 0 16px;
   background-color: var(--blue);
   height: 756px;
}

