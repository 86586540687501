@import '~@o4c/css/components/pills';

.workflow-task {
   width: 100%;
   display: flex;
   flex: 0 0 100px;
   flex-direction: column;
   

   .task-content {
      display: flex;
      flex-direction: column;
      flex: 0 0 80px;
      
      margin: 2.5px 0;

      .task-header {
         display: flex;
         flex-direction: row;
   
         margin: 2.5px 0;
   
         .date{
            color: var(--muted-white);
            font-size: 11px;
   
            display: flex;
            flex: 9;
         }
   
         .task-type {
            display: flex;
            
            .pill {
               background-color: var(--dark-blue);
               font-size: 10px;
               color: var(--white);
            }
         }
      }

      .task-title {
         color: var(--orange);

         font-weight: 600;
         font-size: 12px;
         text-transform: uppercase;
         margin-bottom: 2px;
      }

      .task-description {
         color: var(--white);
         font-size: 16px;
         font-weight: 600;

         margin-bottom: 15px;
      }

   }

   border-bottom: 1px solid var(--dark-blue);

   &:hover {
      cursor: pointer;
   }
}