@import "~@o4c/css/components/cards";
@import "~@o4c/css/components/buttons";
@import "~@o4c/css/core/fonts";


.footer {
   display: flex;
   flex: 0 0 84px;
   width: 100%;
   margin: auto;
   align-items: center;

}

.synergy-button{

   .button-text {
      font-size: 11px;
      color: var(--orange);
      font-weight: 600;
   }
}